.text-mutedd {
  font-family: "Titillium Web", sans-serif;
  color: white;
}
.text-nav {
  font-family: "Titillium Web", sans-serif;
  color: white;
}

.hr-tag {
  border: solid 2px #8e774d;
}
