@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");
.card-title {
  font-family: "Titillium Web", sans-serif;
  font-weight: bolder;
}

.card-text {
  font-family: "Titillium Web", sans-serif;
  text-align: center;
  color: black;
  font-size: 18px;
}

.card {
  margin-bottom: 10px;
  height: 100%;
  border: none;
}

.title-under-text {
  font-weight: bold;
}

.collapse-btn {
  font-family: "Titillium Web", sans-serif;
  background-color: black;
  border: 2px solid black;
  color: white;
  padding: 5px 10px;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  margin: 10px 30px;
  cursor: pointer;
  text-decoration: none;
  border-radius: 30px;
  font-weight: bold;
}

.collapse-btn:hover {
  color: #b8860b;
}

.spec-ing {
  font-size: 18px;
}

.h1-title {
  font-size: 50px;
  color: rgb(0, 0, 0);
  font-family: "Titillium Web", sans-serif;
  font-weight: bold;
}

.geeks {
  overflow: hidden;
  margin: 0 auto;
}

.geeks img {
  width: 100%;
  transition: 0.5s all ease-in-out;
}

.geeks:hover img {
  transform: scale(1.5);
}

.peach-ice {
  color: rgb(222, 109, 188);
}

.strawberry-ly {
  color: rgb(235, 109, 155);
}

.grape-straw {
  color: #854219;
}

.mango {
  color: #cf8e0c;
}

.blood-orange {
  color: #bb2f1d;
}

.grape-para {
  color: rgb(146, 59, 120);
}

.blue-rasp {
  color: rgb(101, 101, 215);
}

.lush {
  color: rgb(65, 163, 66);
}

.apple-ice {
  color: rgb(35, 116, 36);
}

.love66 {
  color: rgb(208, 28, 31);
}
.mint {
  color: rgb(42, 167, 164);
}

.cola {
  color: #6d3716;
}

.straw-apple {
  color: rgb(48, 122, 49);
}

.guava {
  color: rgb(233, 123, 123);
}

.tobacco {
  color: rgb(68, 28, 28);
}

.cocktail {
  color: rgb(124, 72, 108);
}

.cuban {
  color: darkblue;
}

.pink-lemonade {
  color: rgb(243, 140, 157);
}
.strawberry-watermelon-gum {
  color: rgb(245, 105, 245);
}

.GRAPE-PARADISE {
  color: purple;
}

.pineapple-lemonade {
  color: yellowgreen;
}

.honey-melon {
  color: orangered;
}

.MENTHOL-MOJITO {
  color: greenyellow;
}

.brazil-mint {
  color: green;
}

.ENERGY-DRINK {
  color: rgb(85, 85, 243);
}

.STRAWBERRY-ICE-CREAM {
  color: orangered;
}

.STRAWBERRY-APPLE {
  color: rgb(115, 175, 26);
}

.mix-berry {
  color: rgb(180, 16, 180);
}
