@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

:root {
  --primary-white: #fff;
  --primary-orange: #f9ab00;
  --primary-dark: rgba(0, 0, 0, 0);
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
}

body {
  background-color: rgb(255, 255, 255);
  font-family: "Titillium Web", sans-serif;
}

.h1-title {
  font-family: "Titillium Web", sans-serif;
  font-size: 50px;
}

.partners-text {
  font-family: "Titillium Web", sans-serif;
  font-size: 50px;
  color: #fff;
}

p {
  font-family: "Mulish", sans-serif;
  font-family: "Titillium Web", sans-serif;
  font-size: 18px;
}
span {
  font-family: "Mulish", sans-serif;
  font-family: "Titillium Web", sans-serif;
}

/**********************************ABOUT******************************/
.geeks {
  overflow: hidden;
  margin: 0 auto;
}

.geeks img {
  width: 100%;
  transition: 0.5s all ease-in-out;
}

.geeks:hover img {
  transform: scale(1.5);
}

.big-text {
  font-family: "Titillium Web", sans-serif;
  font-size: 25px;
  font-weight: bold;
  border: none;
}

.icon-text {
  background-color: #8e774d;
  border-radius: 20px;
  font-weight: bold;
}

/************************OURTASTE*****************/
